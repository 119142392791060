<template>
  <div class="resources-menu">
    <div class="begin">
      <div class="header bg-white rounded py-4">
        <div
          class="part-1 text-black cursor-pointer py-4 pl-8"
          :class="{ 'selected_choice': position === 1 }"
          @click="changePosition(1)"
        >
          {{ $t('articleMenu') }}
        </div>
        <div
          class="part-1 text-black cursor-pointer py-4 pl-8"
          :class="{ 'selected_choice': position === 2 }"
          @click="changePosition(2)"
        >
          {{ $t('quiz') }}
        </div>
        <!-- <div
          class="part-1 text-black cursor-pointer py-4 pl-8"
          :class="{ 'selected_choice': position === 3 }"
          @click="changePosition(3)"
        >
          {{ $t('courseQuestion') }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'resources-menu',
  data () {
    return {
      position: 1
    }
  },
  methods: {
    changePosition (index) {
      this.position = index
      this.$emit('updatePosition', this.position)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .selected_choice {
    background: rgba(0, 0, 0, 0.08);
    border-left: 4px solid $pass_marron;
  }
</style>
