<template>
  <div class="is-category">
    <div class="begin">
      <div class="cont bg-white rounded py-10 px-6">
        <div v-if="isLoader" class="loader text-center py-4">
          <clip-loader color="#B28B67"></clip-loader>
        </div>
        <category-list
          v-for="(item, i) in allCategories"
          :key="i"
          :categories-lists="item"
          @click.native="showArticles(i)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryList from '../../article/category-list'
import http from '../../../../plugins/http'
import apiRoute from '../../../../router/api-routes'
import ClipLoader from 'vue-spinner/src/ScaleLoader'
export default {
  name: 'is-category',
  components: { ClipLoader, CategoryList },
  data () {
    return {
      isLoader: true,
      allCategories: []
    }
  },
  created () {
    this.getAllCategory()
  },
  methods: {
    showArticles (index) {
      this.$emit('categoryInfo', this.allCategories[index])
      this.$emit('showArticles', true)
    },
    getAllCategory () { // GET BLOG CATEGORIES
      http.post(apiRoute.baseURL + apiRoute.getCategoryByType, {
        type: 'BLOG'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.allCategories = response
        this.isLoader = this.allCategories.length === 0
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../../assets/style/sass/variables";
</style>
