<template>
  <div class="resources">
    <div
      v-if="!isArticle && !addArticle && !createQuiz"
      class="begin"
    >
      <div class="header">
        <div class="flex items-center justify-between">
          <div class="title text-black font-semibold text-lg">
            {{ $t('resourcesMenu') }}
          </div>
          <div class="flex-one current-month ">
            <div class="search-bloc flex items-center justify-between">
              <div v-if="isPosition === 1" class="select-pays ml-4">
                <button-base
                  :label="$t('addCategoryButton')"
                  @click.native="openAddCategory"
                />
              </div>
              <div v-if="isPosition === 2" class="select-pays ml-4">
                <button-base
                  :label="$t('createQuizButton')"
                  @click.native="openCreateQuiz"
                />
              </div>
            </div>
          </div>
        </div><br>
      </div>
      <div class="body mt-10 flex">
        <div class="menu w-1/4">
          <resources-menu
            @updatePosition="updatePosition"
          />
        </div>
        <div class="side w-3/4 ml-4">
          <div v-if="isPosition === 1" class="category">
            <is-category
              @categoryInfo="categoryInfo"
              @showArticles="showArticles"
            />
          </div>
          <div v-if="isPosition === 2" class="quizz">
            <is-quiz
              @updateQuiz="updateQuiz"
              @updateQuizInfo="updateQuizInfo"
            />
          </div>
        </div>
      </div>
    </div>
    <article-by-categ
      v-if="isArticle"
      :is-category-info="categoryName"
      @closeArticleCategory="closeArticleCategory"
      @openAddArticle="openAddArticle"
    />
    <add-article
      v-if="addArticle"
      @closeAddArticle="closeAddArticle"
    />
    <create-quiz
      v-if="createQuiz"
      :is-quiz-info="quizInformation"
      @closeCreateQuiz="closeCreateQuiz"
    />
    <add-category
      v-if="addCategory"
      @closeAddCategory="closeAddCategory"
      @openSuccessAdd="openSuccessAdd"
    />
    <success-add
      v-if="successAdd"
      :message="$t('newCategorySuccess')"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import ButtonBase from '../../components/helper/add/button-base'
import ResourcesMenu from '../../components/helper/resources/resources-menu'
import IsCategory from '../../components/helper/resources/article/is-category'
import ArticleByCateg from '../../components/helper/resources/article/article-by-categ'
import AddArticle from '../../components/helper/article/add-article'
import IsQuiz from '../../components/helper/quiz/is-quiz'
import CreateQuiz from '../../components/helper/quiz/create-quiz'
import AddCategory from '../../components/helper/article/add-category'
import SuccessAdd from '../../components/helper/success-add'
export default {
  name: 'index',
  components: { SuccessAdd, AddCategory, CreateQuiz, IsQuiz, AddArticle, ArticleByCateg, IsCategory, ResourcesMenu, ButtonBase },
  data () {
    return {
      isPosition: 1,
      isArticle: false,
      categoryName: {},
      addArticle: false,
      createQuiz: false,
      addCategory: false,
      successAdd: false,
      quizInformation: {}
    }
  },
  methods: {
    updateQuizInfo (val) {
      this.quizInformation = val
    },
    closeSuccess (val) {
      this.successAdd = val
      window.location.reload()
    },
    openSuccessAdd (val) {
      this.successAdd = val
      this.addCategory = false
    },
    closeAddCategory (val) {
      this.addCategory = val
    },
    openAddCategory () {
      this.addCategory = true
    },
    updateQuiz (val) {
      this.createQuiz = val
      this.$store.dispatch('CREATE_NEW_QUIZ', false)
    },
    openCreateQuiz () {
      this.createQuiz = true
      this.$store.dispatch('CREATE_NEW_QUIZ', true)
    },
    closeCreateQuiz (val) {
      this.createQuiz = val
    },
    openAddArticle (val) {
      this.addArticle = val
      this.isArticle = false
      // this.$store.dispatch('CREATE_NEW_QUIZ', true)
    },
    closeAddArticle (val) {
      this.addArticle = val
    },
    categoryInfo (val) {
      this.categoryName = val
    },
    showArticles (val) {
      this.isArticle = val
    },
    closeArticleCategory (val) {
      this.isArticle = val
    },
    updatePosition (val) {
      this.isPosition = val
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .resources {
    padding: 2rem 4rem 4rem 1.5rem;
  }
</style>
