<template>
  <div class="article-by-categ">
    <div class="begin">
      <div class="header">
        <div class="back">
          <svg-icon
            name="ic_go-back"
            original
            class="w-8 h-8 cursor-pointer"
            @click="goBack"
          />
        </div><br>
        <div class="flex items-center justify-between">
          <div class="title flex text-lg">
            <div class="page-name mr-2">{{ $t('articleMenu') }}</div>
            <div class="gender text-black font-medium">{{ '/ ' + ' ' + isCategoryInfo.label }}</div>
          </div>
          <div class="flex-one current-month ">
            <div class="search-bloc flex items-center justify-between">
              <div class="select-pays">
                <button-base
                  :label="$t('addArticleButton')"
                  @click.native="showAddArticle"
                />
              </div>
            </div>
          </div>
        </div><br>
        <div class="body mt-4">
          <div class="listing flex flex-wrap">
            <article-list
              v-for="(item, i) in articles"
              :key="i"
              :article-list="item"
              class="w-1/3 pb-12 mr-6"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleList from '../../article/article-list'
import http from '../../../../plugins/http'
import apiRoute from '../../../../router/api-routes'
import ButtonBase from '../../add/button-base'
export default {
  name: 'article-by-categ',
  components: { ButtonBase, ArticleList },
  props: {
    isCategoryInfo: Object
  },
  data () {
    return {
      articles: []
    }
  },
  created () {
    this.getAllArticle()
  },
  methods: {
    getAllArticle () {
      http.get(apiRoute.baseURL + apiRoute.getAllArticle, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        console.log(response)
        this.articles = response
        this.$emit('numberArticle', this.articles.length)
      }).catch(error => {
        console.log(error)
      })
    },
    showAddArticle () {
      this.$emit('openAddArticle', true)
    },
    goBack () {
      this.$emit('closeArticleCategory', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../../assets/style/sass/variables";
  .article-list {
    width: 31%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
</style>
